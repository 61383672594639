@keyframes text_load {
    0%{
        transform: translateY(4rem);


    }
    100%{
        transform: translateY(0);

    }
}

@keyframes image_load {
    0%{

        opacity: 0;
    }
    100%{
      
        opacity: 1;
    }
}

