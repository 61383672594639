

#Introduction{
    margin-bottom: 3rem;
    padding: 0px;
    /* display: block; */
    /* height: 20rem; */
    
}

.maxwidth-60{
    max-width: 60%;
    
}

.title{
    font-size: 4rem;
    font-weight: 600;
    margin: 1rem 0rem 1rem 6rem;
    color: var(--main-text);
    line-height: 3.5rem;
    animation: text_load 0.3s ease-in;
    
}

.name_title{
    font-size: 4rem;
    font-weight: 600;
    margin: 1rem 0rem 1rem 6rem;
    color: var(--main-text);
    line-height: 3.5rem;
    animation: text_load 0.3s ease-in;
    
}

.job_title{
    font-size: 4rem;
    font-weight: 600;
    margin: 0rem 0rem 1rem 6rem;
    color: var(--main-text);
    line-height: 3.5rem;
    animation: text_load 0.3s ease-in;
    
}

.dynamic_text{
    font-size: 4rem;
    font-weight: 600;
    color:  var(--main-text);
    line-height: 3.5rem;
    animation: text_load 0.3s ease-in;
}


.typed-cursor {
    font-size: 4rem;
    animation: blink 1s infinite;
    animation: text_load 0.3s ease-in;
}


.paragraph{
    color: var(--dark-grey);
    font-weight: 250;
    font-size: 18px;
    margin: 1rem 0 1rem 6rem;
    line-height: 2rem;
    animation: text_load 0.3s ease-in;

}

.paragraph > *{
    color: var(--dark-grey);
}

.socialMediaMenu_Container{
    margin: 24px 0 1rem 6rem;
    display: flex;
    gap: 1.5rem;
    animation: text_load 0.3s ease-in;
}

.socialIcon{
   width: 2rem;
   height: 2rem;
   transition: opacity 0.3s ease;
   opacity: 0.6; 
    fill: var(--main-text);
}

svg:hover{
    opacity: 1; 
}

.substackicon{
    width: 18px;

}

@media (max-width: 903px) {
    .name_title{
        font-size: 2rem;
        margin: 4rem 1rem 0 1rem;
        line-height: 1;
    }
    .job_title{
        font-size: 2rem;
        margin: 1rem 1rem 0 1rem;
        line-height: 1;
    }
    .dynamic_text{
        font-size: 2rem;
    }

    .typed-cursor {
        font-size: 2rem;
    
      }
    .paragraph{
        font-size: 1rem;
        margin: 1rem 1rem 0 1rem;
        max-width: 80%;

    }
    .socialMediaMenu_Container{
        margin: 1rem 1rem 0 1rem;
    }
  
    .socialIcon{
        width: 1.6rem;
        height: 1.6rem;
      
     }

     .substackicon{
        width: 15px;
    
    }
    .maxwidth-60{
        max-width: 100%;
    }
}