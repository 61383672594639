*{
    font-family: ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol, Noto Color Emoji;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-size: 14px;
}

:root{



    --main-text: rgb(244,244,245);
    --dark-grey: rgb(161,161,170); 
    --light-grey: rgb(161,161,170); 
    --border-grey: rgb(42, 43, 42);
    --hover-grey: rgb(32,32,35);
    --accent-color: rgb(17, 185, 164);
    --background: black;
    --semi-background: rgb(24,24,27);
    --nav-bar: rgb(37,37,41);
    --shadow-color: rgba(39,39,42,.05);
    --gitlink: rgb(34,188,136);
}

.light_theme{

    --main-text: rgb(39,39,42);
    --dark-grey: rgb(82,82,91); 
    --light-grey: rgb(161,161,170); 
    --border-grey: rgb(244,244,245);
    --hover-grey: rgb(250, 250, 250);
    --accent-color: rgb(17, 185, 164);
    --background: rgb(250,250,250);
    --semi-background: white;
    --nav-bar: rgb(255,254,254);
    --shadow-color: rgba(39,39,42,.05);

}

.section_title{
    font-weight: 700;
    margin: 1rem 0 1rem 6rem;
    font-size: 2.5rem;
    width: max-content;
    border-bottom: 1px solid rgb(228,228,231) ;
}

.white_background{
    background: var( --semi-background);
    width: 80%;
    max-width: 87rem;

    margin-top: 0;;
    margin-left: auto;
    margin-right: auto;

    border-left: 1px solid var(--border-grey);
    border-right: 1px solid var(--border-grey);

}

@media (max-width: 640px) {
    .white_background {
        width: 100%;
    }
}

body{
    background-color: var(--background);
}

html, body{
    overflow-x: hidden;
    margin: 0;
    
}

