
#section_title{
    font-size: 3.5rem;
    font-weight: 600;
    margin: 6rem 0rem 1rem 6rem;
    color: var(--main-text);
    width: 60%;
    
}
.margin-title{
    margin-bottom: 3rem;
}

.margin-title > *{
    margin-bottom: 28px;
}

.Projects_Container{
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 4rem 1rem 4rem;
    gap: 1rem;
    animation: text_load 0.3s ease-in;
}

.Project_items{
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;

    gap: 1rem;
    width: 32%;
    box-shadow: 5px 5px 15px var(--shadow-color);
    border: 1px solid var(--border-grey);
    color: var(--main-text);
}

.project_image{
    width: 100%;
    min-height: 13rem;
    object-fit: cover;
    border-radius: 1rem;

}

.Project_items:hover{
    scale: 1.05;
    transition: background 0.15s ease;
    background-color: var(--hover-grey) ;
}

.project_title{
    font-weight: 700;
}

.project_description{
    font-weight: 250;

}

.linkImg{
    width: 1.5rem;
    height: 1.5rem;
}

.github_link{

    transition: opacity 0.3s ease;
    opacity: 0.6; 
    width: fit-content;


}
.github_link:hover{
    opacity: 1.1; 
}

.github_link span{
   float: right;
   color: var( --accent-color);
}

.github_link img{
    float: left;
 }


.see_live{
    background-color: var(--accent-color);
    font-size: 0.875rem;
    border-radius: 5px;
    padding: 3px 5px 3px 5px;
    color: var(--main-text);
}

.see_live:hover{
    background-color: var(--light-grey);
    color: white;
}

.buttons_container{
    display: flex;
    gap: 7px;
    margin-top: auto;
}


.project_skills{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.project_skills > * {
    padding: 3px;
    border-radius: 6px;
    /* background-color:rgb(240, 240, 240); */
}

.project_skills > span:nth-child(1){
    color: rgb(63,159,239);
}

.project_skills > span:nth-child(2){
    color: rgb(34,188,136);
}


.project_skills > span:nth-child(3){
    color: rgb(245,56,120);
}

.project_skills > span:nth-child(4){
    color: rgb(191,103,32);
}

@media (max-width: 1017px) {
    .Project_items{

        width: 48%;
        
    }

  
}

@media (max-width: 903px) {
    #section_title{
    margin: 1rem 0 1rem 4rem;
    }

  .Projects_Container{
    flex-direction: column;
    margin: 1rem 1rem 1rem 1rem;

  }

  .Project_items{
    width: 100%;

  }
  
}