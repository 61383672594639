.Navbar {    
    height: 5rem;
    padding: 1rem 3rem;
    display: flex;
 
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding: 5rem;
    margin-bottom: 6rem;

}

.desktopMenu{
    margin-left:  3rem;     /* added div and this WOW this worked to center the button cause the button is 2.7 width */
    box-shadow: 5px 5px 15px var(--shadow-color);
    border: 1px var(--border-grey) solid;
    border-radius: 3rem;
    padding: 10px 0 10px 0;
    background-color: var(--nav-bar);
   
}

.desktopMenuListItem{
    margin: 0.2rem;
    cursor: pointer;
    font-size: 1rem;
    color: var(--main-text);
    padding: 10px 16px 10px 16px;
    border-left: none !important;
}

.desktopMenuListItem:hover{
    /* border-bottom: 2px solid var(--accent-color); */
    color:  var(--accent-color);

}

#apperance_b{
    border: 1px var(--border-grey) solid;
    justify-self: flex-end;
    background-color: var(--nav-bar);
    box-shadow: 5px 5px 15px var(--shadow-color);
    cursor: pointer;
    height: 3.1rem;
    min-width: 3.7rem;

    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.apperance_icon{
    width: 1.2rem;
    position: relative;
}

.Home{
    transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out;
    color: var(--main-text);
    background-color: var(--border-grey);
    border-radius: 3rem;
}


.Home:hover{
    color: var(--background);
    background-color: var(--main-text);
}

.active{
    color: var(--accent-color);
}

.Hamburger, .HamburgerMenu{
    display: none;
}

@media (max-width: 903px) {
    .Navbar {    
        justify-content: end;
        margin: 0 0rem 2.5rem 0rem;
        padding: 0;
    }

    .Hamburger {
        display: flex;
        align-items: center;
        border-radius: 3rem;
        background-color: var(--nav-bar); 
        padding: 0.7rem 1rem;
        font-size: 1rem; 
        font-weight: 500; 
        color: var(--main-text); 
        border: 1px solid var(--border-grey);
        box-shadow: 0 10px 15px -3px var(--shadow-color), 0 4px 6px -2px var(--shadow-color); 
      }

      .HamburgerMenu{
        z-index: 300;
        display: flex;
        flex-direction: column;
        position: absolute;

        padding: 2rem;
        font-size: 1rem;
      
        top: 2rem;
        
        width: 90%;
        margin: 0 1rem 0 1rem;
        height: 40%;
        border-radius: 2rem;
        background-color: var(--background);
        border: 1px solid var(--shadow-color);
        box-shadow: 0 10px 15px -3px var(--shadow-color), 0 4px 6px -2px var(--shadow-color); 
      
        backdrop-filter: blur(10px); 
        transition: all 0.3s ease;

      }

      .navtitle{
        font-weight: 400;
        float: left;
        width: 50%;
      }

      .exit-button{
        border: none;
        width: fit-content;
        float: right;
      }

      .exit_icon{
        stroke: var(--main-text);
      }

      .HamburgerMenu > *{
        color: var(--main-text);
        margin-top: 1rem;
      }
     
    
      .Hamburger_icon {
        margin-left: 0.75rem; 
        width: 0.5rem; 
        stroke: var(--light-grey);
      }
      
        .desktopMenu{
                display: none;
        }
}

.hidden {
    display: none;
  }